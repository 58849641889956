import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAppContext } from '@/context/state';
import { IconUser } from '@/components/atoms';

import {
  CartIconContainer,
  WishlistIconContainer,
} from '@/components/molecules';

const Ul = styled.ul`
  display: flex;
  align-items: center;
  li {
    margin-right: 12px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const Li = styled.li``;

const IconContainer = styled.div`
  cursor: pointer;
`;

interface IHeaderIcons {
  openCart: () => void;
}

const HeaderIcons: React.FC<IHeaderIcons> = ({ openCart }) => {
  const context = useAppContext();

  const cartPage = context?.settings ? context.settings['cartPage'] : false;
  const enableWishlist = context?.settings
    ? context.settings['enableWishlist']
    : true;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Ul>
      {!isMobile && (
        <Li>
          <Link href={'/account'}>
            <a>
              <IconUser width={22} height={22} />
            </a>
          </Link>
        </Li>
      )}
      {enableWishlist && (
        <Li>
          <Link href={'/wishlist'}>
            <a>
              <WishlistIconContainer />
            </a>
          </Link>
        </Li>
      )}
      <Li>
        {cartPage ? (
          <Link href={'/cart'}>
            <a>
              <CartIconContainer />
            </a>
          </Link>
        ) : (
          <IconContainer onClick={openCart}>
            <CartIconContainer />
          </IconContainer>
        )}
      </Li>
    </Ul>
  );
};

export default HeaderIcons;
