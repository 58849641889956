import React, { useState } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { makeLink } from '@/utils/Link';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useAppContext } from '@/context/state';
import useConditional from '@/hooks/useConditional';
import { IconDoubleArrow, LinkHandler, Typography } from '@/components/atoms';
import {
  Accordion,
  MainMenuHeader,
  StaticAccountMenu,
} from '@/components/molecules';
import { IMainMenuMobileCmp } from './IMainMenuMobileCmp';
import { stringToSentenceCase } from '@/utils/index';

interface ICustomTypo {
  $isHighlight?: boolean;
}
interface StyleProps {
  padding?: boolean;
  margin?: boolean;
}
const OuterAccordionWrapper = styled.div`
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid ${props => props.theme.mainMenuMobile.borderColor};
  div:last-child {
    border-bottom: none;
  }
`;
const OuterAccordionContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  line-height: 20px;
  padding: 0 8px;
  font-size: 14px;
`;
const InnerAccordionContent = styled.div`
  border-left: 1px solid black;
  border-radius: 0;
  padding: 4px;
  padding-left: 8px;
  margin-bottom: 16px;
`;
const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 16px 0 24px;
  & .MuiAccordionSummary-root,
  & .MuiAccordionSummary-root.Mui-expanded {
    text-transform: capitalize !important;
  }
`;

const InnerAccordionWrapper = styled.div`
  width: 200px;
  div:last-child {
    border-bottom: none;
  }
`;
const LinkWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  border-bottom: 1px solid ${props => props.theme.mainMenuMobile.borderColor};
  display: flex;
  align-items: center;
  a {
    color: ${props => props.theme.paletteDefault.primary.main};
    &:visited {
      color: ${props => props.theme.paletteDefault.primary.main};
    }
    &:hover {
      color: ${props => props.theme.paletteDefault.primary.main};
    }
  }
`;
const CurrencyLinkWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  padding-left: 48px;
  border-bottom: 1px solid ${props => props.theme.mainMenuMobile.borderColor};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledAnchor = styled.a<ICustomTypo>`
  display: block;
  color: ${props =>
    props.$isHighlight
      ? props.theme.typog.highlightColor
      : props.theme.paletteDefault.primary.main};
`;

const OuterAccordionAnchor = styled.a`
  display: block;
  line-height: 1.5em;
  font-size: 14px;
  padding: 16px 0;
  text-transform: capitalize;
  color: ${props => props.theme.paletteDefault.primary.main};
  &:visited {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
  &:hover {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  margin-right: 4px;
`;

const CustomTypography = styled(Typography)<ICustomTypo>`
  font-size: 16px;
  line-height: 24px;
  color: ${props =>
    props.$isHighlight ? props.theme.typog.highlightColor : 'inherit'};
`;

const CurrencyContainer = styled.div`
  overflow: auto;
`;

const LinkHandlerWrapper = styled.div<StyleProps>`
  padding: ${props => (props.padding ? '0 8px' : 0)};
  ${props =>
    props.margin &&
    css`
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    `}
`;

const isShallowLink = (url: string, pageType: string | null | undefined) => {
  let shallow = false;
  if (url && url.includes('/collections') && pageType === 'collection') {
    shallow = true;
  }
  return shallow;
};

const MainMenuMobile: React.FC<IMainMenuMobileCmp> = props => {
  const { locale, clickHandler, currency, isLogged, accountViewOnly } = props;

  const context = useAppContext();
  const pageType = context?.pageType;
  const selectedCurrency = useSelector(
    (state: RootState) => state.currencyReducer.currency
  );
  const [showCurrencies, setShowCurrencies] = useState(false);

  const toggleShowCurrencies = () => {
    setShowCurrencies(!showCurrencies);
  };

  const { conditionalContent } = useConditional(props.fields?.menu || []);
  let accordions = [];

  accordions = conditionalContent.map((menuItem: any, index) => {
    const menuItemTitle = menuItem?.fields?.link?.fields?.title;
    const menuItemHref = menuItem?.fields?.link?.fields?.href;
    const menuItemType = menuItem?.fields?.link?.fields?.type;
    const menuItemHighlight = !!menuItem?.fields?.link?.fields?.highlight;

    const menuItemMobileLinkLabel = menuItem?.fields?.mobileLinkLabel;
    let menuItemLinksGroup = menuItem?.fields?.linkGrouped;

    if (menuItemLinksGroup && menuItemLinksGroup.length > 0) {
      menuItemLinksGroup = menuItemLinksGroup.map(
        (linksGroup: any, index: number) => {
          const linksGroupTitle = linksGroup?.fields?.heading;

          let links = linksGroup?.fields?.link;

          if (links) {
            links = links.map((link: any, index: number) => {
              const href = link?.fields?.href;
              const linkType = link?.fields?.type;
              const linkHighlight = !!link?.fields?.highlight;
              let url;
              if (href) {
                url = makeLink(linkType, href, menuItemHref);
              }

              return url ? (
                <LinkHandlerWrapper
                  padding={true}
                  margin={true}
                  key={`sublink-${index}`}>
                  <LinkHandler
                    href={url}
                    passHref
                    shallow={isShallowLink(url, pageType)}>
                    <StyledAnchor
                      onClick={clickHandler}
                      $isHighlight={linkHighlight}>
                      {link.fields.text}
                    </StyledAnchor>
                  </LinkHandler>
                </LinkHandlerWrapper>
              ) : null;
            });
          }
          return linksGroupTitle ? (
            <InnerAccordionWrapper key={`linksGroup-${index}`}>
              <Accordion
                heading={linksGroupTitle || ''}
                template="menuSubEntry">
                <InnerAccordionContent className={'customWrapper'}>
                  {links}
                </InnerAccordionContent>
              </Accordion>
            </InnerAccordionWrapper>
          ) : null;
        }
      );
    }
    let href;

    if (menuItemLinksGroup && menuItemLinksGroup.length > 0) {
      href = makeLink(menuItemType, menuItemHref, null);
      return (
        <OuterAccordionWrapper key={`menuItem-${index}`}>
          <Accordion
            heading={stringToSentenceCase(menuItemTitle) || ''}
            template="menuEntry"
            isHighlight={menuItemHighlight}>
            <OuterAccordionContent className={'customWrapper'}>
              {menuItemMobileLinkLabel && (
                <LinkHandler
                  href={href}
                  passHref
                  shallow={isShallowLink(href, pageType)}>
                  <OuterAccordionAnchor
                    onClick={clickHandler}
                    className="smalltitle">
                    {stringToSentenceCase(menuItemMobileLinkLabel)}
                  </OuterAccordionAnchor>
                </LinkHandler>
              )}

              {menuItemLinksGroup}
            </OuterAccordionContent>
          </Accordion>
        </OuterAccordionWrapper>
      );
    } else {
      href = makeLink(menuItemType, menuItemHref, null);
      if (menuItemHref) {
        return (
          <LinkWrapper key={`link-${index}`}>
            <LinkHandler
              href={href}
              passHref
              shallow={isShallowLink(href, pageType)}>
              <a onClick={clickHandler}>
                <CustomTypography
                  datatype="heading_3"
                  className="smalltitle"
                  $isHighlight={menuItemHighlight}>
                  {stringToSentenceCase(menuItemTitle)}{' '}
                  <IconDoubleArrow width={10} height={10} />
                </CustomTypography>
              </a>
            </LinkHandler>
          </LinkWrapper>
        );
      } else {
        return null;
      }
    }
  });

  let currencyLinks;
  if (showCurrencies) {
    currencyLinks = currency.allCurrencies.map((currCurrency, index) => {
      if (currCurrency.toUpperCase() !== selectedCurrency?.isoCode) {
        return (
          <CurrencyLinkWrapper key={`currlink-${index}`}>
            {currCurrency && (
              <IconWrapper>
                <Image
                  src={`/asset/flags/${currCurrency}.svg`}
                  alt={`${currCurrency} flag`}
                  width={30}
                  height={20}
                  unoptimized={true}
                />
              </IconWrapper>
            )}
            <div
              onClick={() => {
                var event = new CustomEvent('changeCurrency', {
                  detail: {
                    currency: currCurrency.toUpperCase(),
                  },
                });
                document.dispatchEvent(event);
                toggleShowCurrencies();
              }}>
              {currCurrency.toUpperCase()}
            </div>
          </CurrencyLinkWrapper>
        );
      }
    });
  }

  return (
    <>
      <MainMenuHeader
        currency={currency}
        onClose={clickHandler}
        locale={locale}
        isLogged={isLogged}
        toggleShowCurrencies={toggleShowCurrencies}
      />
      {showCurrencies ? (
        <CurrencyContainer>{currencyLinks}</CurrencyContainer>
      ) : (
        <>
          {!accountViewOnly || (accountViewOnly && isLogged) ? (
            <AccordionsContainer>{accordions}</AccordionsContainer>
          ) : null}
          {!isLogged && <StaticAccountMenu />}
        </>
      )}
    </>
  );
};

export default MainMenuMobile;
