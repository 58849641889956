import { Typography } from '@/components/atoms';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import ProductCardV2 from '../ProductCard';
import { ISearchResult } from './ISearchResultCmp';

const SearchResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 800px; */
  height: 100%;
`;
const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  /* ${props => props.theme.breakpoints.up('sm')} {
    margin-left: 16px;
    margin-right: 16px;
  } */
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    padding: 0 8px;
  }
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0;
  }
  ${props => props.theme.breakpoints.up('tablet')} {
    column-gap: 20px;
    padding: 0;
  }
`;

const StyledAnchor = styled.a`
  display: block;
  cursor: pointer;
`;

const LightTypography = styled(Typography)`
  font-weight: 400;
  text-decoration: underline;
  color: ${props => props.theme.paletteDefault.grey1};
`;
const ItalicTypography = styled(Typography)`
  font-style: italic;
`;
const BoldTypography = styled(Typography)`
  font-weight: 600;
`;

const SearchResult: React.FC<ISearchResult> = ({
  products,
  locale,
  toggleSearchMode,
  inputvalue,
}) => {
  const { t } = useTranslation('common');
  let cards: JSX.Element[] = [];
  if (products?.items.length) {
    cards = products.items.map((product, index) => {
      return (
        <div
          key={`searchcard-${product.handle}`}
          onClick={() => {
            toggleSearchMode();
          }}>
          <ProductCardV2
            product={product}
            positionindex={index + 1}
            type="collection list"
            template="search"
            customization={{
              showColorVariants: false,
              showHeader: false,
            }}
          />
        </div>
      );
    });
  }

  return products ? (
    <SearchResultsWrapper>
      <ResultsHeader>
        <BoldTypography datatype="body_1">{t('product_plural')}</BoldTypography>
        {products && products.pagination.hasNextPage && (
          <Link href={`/search?q=${inputvalue}`} locale={locale} passHref>
            <StyledAnchor onClick={toggleSearchMode}>
              <LightTypography datatype="caption">
                {t('search.view-more', {
                  count: products.pagination.totalItems,
                })}
              </LightTypography>
            </StyledAnchor>
          </Link>
        )}
      </ResultsHeader>
      {cards && cards.length === 0 ? (
        <ItalicTypography datatype="body_1">
          {t('search.no-results')}
        </ItalicTypography>
      ) : (
        cards && <ProductsWrapper>{cards}</ProductsWrapper>
      )}
    </SearchResultsWrapper>
  ) : null;
};

export default SearchResult;
