import React from 'react';
import styled from 'styled-components';
import { Button, LinkHandler, Typography } from '@/components/atoms';
import { IHeaderLinksCmp } from './IHeaderLinksCmp';
import { makeLink } from '@/utils/Link';

const Ul = styled.ul`
  display: flex;
  align-items: center;
  li {
    margin-right: 16px;

    ${props => props.theme.breakpoints.up('laptop')} {
      margin-right: 24px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  a,
  a:visited {
    color: inherit;
  }
`;
type StyledProps = {
  scrollTriggered: boolean;
};
const LandingButton = styled(Button)<StyledProps>`
  margin: 0 8px;
  border: 2px solid ${props => props.theme.paletteDefault.primary.main};
  background-color: ${props =>
    props.scrollTriggered
      ? props.theme.paletteDefault.primary.main
      : props.theme.paletteDefault.offWhite};
`;

const LandingRegisterTypography = styled(Typography)<StyledProps>`
  color: ${props =>
    props.scrollTriggered
      ? props.theme.paletteDefault.offWhite
      : props.theme.paletteDefault.primary.main};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08rem;
`;

const LoginWrapper = styled.div<StyledProps>`
  margin: 0 8px;
  padding: 1.5px;
  font-weight: 600;
  color: ${props =>
    props.scrollTriggered
      ? props.theme.paletteDefault.primary.main
      : props.theme.paletteDefault.offWhite};
  border-bottom: 1.5px solid currentColor;
`;

const HeaderLinks: React.FC<IHeaderLinksCmp> = ({
  links,
  template = 'default',
  scrollTriggered,
}) => {
  let headerLinks = null;

  if (links && links.length > 0) {
    headerLinks = links.map((link, index) => {
      const url = makeLink(link.fields?.type, link.fields?.href, null);
      if (link.fields?.href === 'account/register') {
        return (
          <li key={`li-${index}`}>
            {template === 'prospect-landing' ? (
              <LandingButton
                variant="contained"
                size="medium"
                scrollTriggered={scrollTriggered}>
                <LinkHandler href={url} source={'header'}>
                  <LandingRegisterTypography
                    scrollTriggered={scrollTriggered}
                    datatype="caption"
                    className="smalltitle">
                    <a>{link.fields.text}</a>
                  </LandingRegisterTypography>
                </LinkHandler>
              </LandingButton>
            ) : (
              <Button variant="outlined" size="small">
                <LinkHandler href={url} source={'header'}>
                  <Typography datatype="caption" className="smalltitle">
                    <a>{link.fields.text}</a>
                  </Typography>
                </LinkHandler>
              </Button>
            )}
          </li>
        );
      } else {
        return (
          <li key={`li-${index}`}>
            {template === 'prospect-landing' ? (
              <LoginWrapper scrollTriggered={scrollTriggered}>
                <Typography datatype="caption">
                  <LinkHandler href={url} passHref>
                    <a>{link.fields.text}</a>
                  </LinkHandler>
                </Typography>
              </LoginWrapper>
            ) : (
              <Typography datatype="caption">
                <LinkHandler href={url} passHref>
                  <a>{link.fields.text}</a>
                </LinkHandler>
              </Typography>
            )}
          </li>
        );
      }
    });
  }
  return <Ul>{headerLinks}</Ul>;
};

export default HeaderLinks;
