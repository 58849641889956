import React from 'react';
import { useTheme } from 'styled-components';

const IconArrowDown: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m10.19,19.64c-.07,0-.13-.03-.17-.1L3.58,4.56c-.03-.1.07-.2.17-.2h2.94c.07,0,.1.04.13.1l5.11,11.65c.03.1.23.1.27,0l4.97-11.65c.03-.07.07-.1.13-.1h2.94c.13,0,.23.1.17.23l-6.31,14.95c-.03.07-.1.1-.17.1h-3.74Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconArrowDown;
