import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { LinkHandler } from '@/components/atoms';
import { Accordion } from '@/components/molecules';

const StaticInnerAccordion = styled.div``;

const StaticAccordionContainer = styled.div`
  padding: 16px 32px;
  a,
  a:visited {
    color: inherit;
  }
`;

const StyledAnchor = styled.a`
  /* padding: 0 8px;
  color: ${props => props.theme.paletteDefault.primary.main};
  display: block;
  margin-bottom: 16px;
  &:visited {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
  &:hover {
    color: ${props => props.theme.paletteDefault.primary.main};
  }
  &:last-child {
    margin-bottom: 0;
  } */
  margin-bottom: 16px;
  display: block;
`;

export const StaticAccountMenu = () => {
  const { t } = useTranslation('common');

  return (
    <StaticAccordionContainer>
      <Accordion
        defaultExpanded={true}
        heading={'Account'}
        template="menuSubEntry">
        <StaticInnerAccordion>
          <LinkHandler href={'/account/login'}>
            <StyledAnchor>{t('menu.log-in')}</StyledAnchor>
          </LinkHandler>
          <LinkHandler href={'/account/register'}>
            <StyledAnchor>{t('menu.sign-up')}</StyledAnchor>
          </LinkHandler>
          <LinkHandler href={'https://airtable.com/shr4oINXHwNYGJqMI'} passHref>
            <StyledAnchor target="_blank">
              {t('menu.apply-as-a-brand')}
            </StyledAnchor>
          </LinkHandler>
        </StaticInnerAccordion>
      </Accordion>
    </StaticAccordionContainer>
  );
};

export default StaticAccountMenu;
