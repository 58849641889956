import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { WishlistIcon } from '@/components/atoms';

const WishlistIconContainer = () => {
  const totalWishlist = useSelector(
    (state: RootState) => state.wishlistReducer.totalWishlist
  );

  return <WishlistIcon total={totalWishlist} />;
};

export default WishlistIconContainer;
