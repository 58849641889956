import React from 'react';
import { useTheme } from 'styled-components';

const IconDivider: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m11.3,20.43s-.07-.04-.07-.09V3.67c0-.06.04-.09.07-.09h1.38c.05,0,.09.04.09.09v16.67c0,.06-.04.09-.09.09h-1.38Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconDivider;
