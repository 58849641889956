import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Accordion as AccordionAtom, Typography } from '@/components/atoms';
import { IAccordion } from './AccordionCmp';
import { TypoDataType } from '@/components/atoms/Typography/Typography';
import Image from 'next/image';
import { useMediaQuery } from '@material-ui/core';
interface IStyleProps {
  template?: string;
  $headingUppercase?: boolean;
  $nopaddingdetails?: boolean;
  $bgColor?: string;
  $isHighlight?: boolean;
  $withNoBorder?: boolean;
  $bigArrow?: boolean;
}

const CustomMuiAccordion = styled(AccordionAtom)<IStyleProps>`
  ${props =>
    props.template !== 'showcase' &&
    css`
      margin: 0;
    `}
  ${props =>
    props.template === 'showcase' &&
    css`
      margin-bottom: 24px;
    `}
  background-color: ${props => props.$bgColor ?? 'inherit'};
  padding: 0;
  &.Mui-expanded {
    ${props =>
      props.template !== 'showcase' &&
      css`
        margin: 0;
      `}
    border-bottom: ${props =>
      props.$withNoBorder ? ' 0px solid' : ' 1px solid'};
    border-color: ${props => props.theme.paletteDefault.grey1};
    &:last-child {
      border-bottom: none;
    }
  }
  &.MuiAccordion-root:before {
    opacity: ${props =>
      props.template === 'showcase' || props.$withNoBorder ? 0 : 1};
  }

  &.MuiAccordion-root.Mui-expanded {
    ${props =>
      props.template === 'showcase' &&
      css`
        margin-bottom: 24px;
      `}
  }

  &.MuiAccordion-root.Mui-expanded:before {
    opacity: ${props =>
      props.template === 'showcase' || props.$withNoBorder ? 0 : 1} !important;
    background-color: ${props => props.theme.paletteDefault.grey1};
  }
  .MuiSvgIcon-root {
    ${props =>
      props.template === 'showcase' &&
      css`
        font-size: clamp(32px, 4vw, 56px);
      `}
  }

  .MuiAccordionSummary-expandIcon {
    color: ${props => props.theme.paletteDefault.primary.main};
    transform: rotate(-90deg);
    margin: 0;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg);
  }
  .MuiAccordionSummary-content {
    margin: ${props => (props.template === 'showcase' ? '16px 0' : 'inherit')};
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: ${props => (props.template === 'showcase' ? '16px 0' : 0)};
  }
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 56px;
    padding: 0;
    ${props =>
      props.$headingUppercase &&
      css`
        text-transform: uppercase;
      `}
  }
  .MuiAccordionDetails-root {
    padding: 0 8px;
    ${props =>
      props.$nopaddingdetails &&
      css<IStyleProps>`
        padding: 0;
        padding-bottom: ${props =>
          props.template === 'showcase' ? 0 : '16px'};
      `}
  }
`;

const CustomTypography = styled(Typography)<IStyleProps>`
  ${props =>
    props.template === 'menuEntry' &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
  color: ${props =>
    props.$isHighlight ? props.theme.typog.highlightColor : 'inherit'};
`;

const Summary = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentWrapper = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props =>
    props.template === 'showcase' &&
    css`
      margin: 0;
      margin-top: clamp(8px, 1.5vw, 16px);
      margin-bottom: clamp(8px, 1.5vw, 16px);
    `}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: clamp(16px, 2vw, 32px);
`;

const Accordion: React.FC<IAccordion> = ({
  heading,
  subHeading,
  template,
  headingUppercase,
  nopaddingdetails,
  icon,
  bgColor,
  isHighlight,
  withNoBorder,
  bigArrow,
  ...props
}) => {
  const theme = useTheme();
  const smallIcon = useMediaQuery(theme.breakpoints.down('sm'));

  let titleTypes: { [key: string]: TypoDataType } = {
    menuEntry: 'body_1',
    menuSubEntry: 'body_1',
    collectionFilters: 'body_1',
    productInfo: 'body_1',
    showcase: 'heading_5',
  };
  let foundType: TypoDataType = 'body_1'; //default
  if (template) {
    foundType = titleTypes[template];
  }

  const summary = (
    <Summary>
      <TitleContainer>
        {icon && (
          <IconContainer>
            <Image
              src={icon.url}
              alt={icon.alt}
              width={smallIcon ? 32 : 48}
              height={smallIcon ? 32 : 48}
              unoptimized={true}
            />
          </IconContainer>
        )}
        <CustomTypography
          template={template}
          datatype={foundType}
          component={template === 'showcase' ? 'h2' : 'p'}
          className={template === 'showcase' ? 'bigtitle' : 'smalltitle'}
          $isHighlight={isHighlight}>
          {heading}
        </CustomTypography>
      </TitleContainer>
      {subHeading && (
        <CustomTypography template={template} datatype={foundType}>
          {subHeading}
        </CustomTypography>
      )}
    </Summary>
  );
  return (
    <CustomMuiAccordion
      {...props}
      summary={summary}
      $nopaddingdetails={nopaddingdetails}
      $headingUppercase={headingUppercase}
      template={template}
      $bgColor={bgColor}
      $withNoBorder={withNoBorder}
      bigArrow={bigArrow}>
      <ContentWrapper template={template}>{props.children}</ContentWrapper>
    </CustomMuiAccordion>
  );
};

Accordion.defaultProps = {
  headingUppercase: true,
};

export default Accordion;
