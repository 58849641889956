import React from 'react';
import styled from 'styled-components';
import MegaMenuContent from '@/components/molecules/MegaMenuContent';
import { WrapperExternal } from '@/components/atoms';
import { IMegaMenuCmp } from './IMegaMenuCmp';

const Container = styled.div`
  border-top: 1px solid ${props => props.theme.header.borderColor};
  width: 100%;
  background: ${props => props.theme.header.navigation.backgroundColor};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1;
  min-height: 380px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  /* padding: clamp(1.5rem, 1.2451rem + 1.0458vw, 2.5rem); */
  &:hover {
    display: block;
  }
` as React.ElementType;

const MegaMenu: React.FC<IMegaMenuCmp> = props => {
  const { menu, locale, parentLink } = props;
  return (
    <Container>
      <WrapperExternal>
        <MegaMenuContent
          groupedLinks={menu}
          locale={locale}
          parentLink={parentLink}
        />
      </WrapperExternal>
    </Container>
  );
};

export default MegaMenu;
