import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { CartIcon } from '@/components/atoms';

const CartIconContainer = () => {
  const cartTotal = useSelector(
    (state: RootState) => state.cartReducer.cartTotal
  );

  return <CartIcon total={cartTotal} />;
};

export default CartIconContainer;
