import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { IDropdown, IOption } from '@/interfaces/index';
import { Link as UiLink } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Typography } from '@/components/atoms';
interface IStyledProps {
  outlined?: boolean;
  expanded?: boolean;
  shadow?: boolean;
  icon?: string;
  active?: boolean;
  maxHeight?: number;
  error?: boolean;
}

const UlComponent = styled.ul<IStyledProps>`
  visibility: ${props => (props.expanded ? 'visible' : 'hidden')};
  opacity: ${props => (props.expanded ? 1 : 0)};
  height: ${props => (props.expanded ? '100%' : 0)};
  cursor: pointer;
  padding: 4px;
  height: auto;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: ${props => props.theme.paletteDefault.white};
  width: 100%;
  box-shadow: inherit;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : 'auto')};
  overflow: auto;
  ${props =>
    props.outlined &&
    css<IStyledProps>`
      border: ${props =>
        props.expanded
          ? `1px solid ${props.theme.paletteDefault.primary.main}`
          : ''};
      border-top: ${props => (props.expanded ? '0px solid' : '')};
      border-bottom-left-radius: ${props =>
        props.expanded ? `${props.theme.shape.borderRadius}px` : `0px`};
      border-bottom-right-radius: ${props =>
        props.expanded ? `${props.theme.shape.borderRadius}px` : `0px`};
      width: calc(100% + 2px);
      left: -1px;
    `}
`;

const LiComponent = styled.li<IStyledProps>`
  background-color: inherit;
  text-decoration-thickness: 3px;
  padding: ${props => (props.icon ? '0' : '12px 6px')};
  text-decoration-line: ${props => (props.active ? 'underline' : 'none')};
  margin: 0 auto;
  ${props =>
    props.outlined &&
    css`
      padding: 16px;
      border-bottom: solid 1px ${props => props.theme.paletteDefault.grey1};
      &:last-child {
        border-bottom: 0;
      }
    `}
  a {
    display: block;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;

const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const WrapperDropdown = styled.div<IStyledProps>`
  cursor: pointer;
  position: relative;
  box-shadow: ${props =>
    props.shadow
      ? '1px 1px 1px 1px ' + props.theme.paletteDefault.grey1
      : 'none'};
  padding: 4px 0;
  height: 100%;
  display: flex;
  align-items: center;
  ${props =>
    props.outlined &&
    css<IStyledProps>`
      border: ${props =>
        props.error && !props.expanded
          ? `1px solid ${props.theme.paletteDefault.error.main}`
          : `1px solid ${props.theme.paletteDefault.primary.main}`};
      padding: 16px;
      padding-bottom: ${props => (props.expanded ? '8px' : '16px')};
      border-radius: ${props => `${props.theme.shape.borderRadius}px`};
      border-bottom-width: ${props => (props.expanded ? '0px' : '1px')};
      border-bottom-left-radius: ${props =>
        props.expanded ? '0px' : `${props.theme.shape.borderRadius}px`};
      border-bottom-right-radius: ${props =>
        props.expanded ? '0px' : `${props.theme.shape.borderRadius}px`};
    `}
`;

const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0;
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  margin-right: 4px;
`;
const ArrowWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const ImageArrow = styled(Image)<IStyledProps>`
  ${props =>
    !props.expanded &&
    css`
      transform: rotate(-90deg);
    `}
`;

const Dropdown: React.FC<IDropdown> = ({
  shadow,
  selected,
  options,
  shallow,
  isExpanded,
  onChangeHandler,
  outlined,
  maxHeight,
  placeholder,
  error,
}) => {
  const router = useRouter();
  const [expanded, setExpanded] = useState(isExpanded || false);
  const [selectedValue, setSelectedValue] = useState(selected);
  const [errorState, setErrorState] = useState(error ? error : false);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleClick = () => {
    if (options && options.length >= 1) {
      setExpanded(!expanded);
    }
  };

  const handleClickAway = () => {
    setExpanded(false);
  };

  const handleOptionClick = (e: any, option: IOption, shallow?: boolean) => {
    if (shallow) {
      e.preventDefault();
    }

    setSelectedValue(option.value);
    setErrorState(false);
    handleClick();

    if (onChangeHandler) {
      onChangeHandler(option.value);
    }
  };

  const arrow = (
    <ImageArrow
      expanded={expanded ? 1 : 0}
      src={`/asset/arrowdownsmall.svg`}
      alt={`arrow ${expanded ? 'down' : 'right'}`}
      width={15}
      height={15}
      unoptimized={true}
    />
  );
  let header;
  if (placeholder && !selected) {
    header = (
      <SelectedOption>
        <PlaceholderContainer>{placeholder}</PlaceholderContainer>
        <ArrowWrapper>{arrow}</ArrowWrapper>
      </SelectedOption>
    );
  } else {
    const selected = options.filter(option => {
      if (selectedValue === option.value) {
        return option;
      }
    });

    header = (
      <SelectedOption>
        {selected && selected.length > 0 ? (
          <OptionWrapper>
            {selected[0].iconPath && (
              <IconWrapper>
                <Image
                  src={selected[0].iconPath}
                  alt={`${selected[0].label} flag`}
                  width={30}
                  height={20}
                  unoptimized={true}
                />
              </IconWrapper>
            )}
            <Typography datatype="body_1"> {selected[0].label}</Typography>
          </OptionWrapper>
        ) : null}

        {options && options.length > 1 && <ArrowWrapper>{arrow}</ArrowWrapper>}
      </SelectedOption>
    );
    options = options.filter(option => {
      return selectedValue !== option.value;
    });
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <WrapperDropdown
        shadow={shadow}
        outlined={outlined}
        expanded={expanded}
        error={errorState}
        onClick={handleClick}>
        {header}

        <UlComponent
          expanded={expanded}
          shadow={shadow}
          outlined={outlined}
          maxHeight={maxHeight}>
          {options &&
            options.map(option => {
              return (
                <LiComponent
                  outlined={outlined}
                  key={option.label}
                  active={
                    option.label === selectedValue ||
                    option.value === selectedValue
                  }
                  icon={option.iconPath}
                  onClick={(e: any) =>
                    handleOptionClick(
                      e,
                      option,
                      option.href ? option.href.shallow : shallow
                    )
                  }>
                  <>
                    <OptionWrapper>
                      {option.iconPath && (
                        <IconWrapper>
                          <Image
                            src={option.iconPath}
                            alt={`${option.label} flag`}
                            width={30}
                            height={20}
                            unoptimized={true}
                          />
                        </IconWrapper>
                      )}
                      {option.href ? (
                        <Link
                          href={{
                            pathname: option.href.pathname,
                            query: {
                              ...option.href.query,
                            },
                          }}
                          locale={shallow ? router.locale : option.locale}
                          shallow={option.href.shallow || shallow}
                          passHref>
                          <UiLink>{option.label}</UiLink>
                        </Link>
                      ) : (
                        <UiLink>{option.label}</UiLink>
                      )}
                    </OptionWrapper>
                  </>
                </LiComponent>
              );
            })}
        </UlComponent>
      </WrapperDropdown>
    </ClickAwayListener>
  );
};

export default Dropdown;
