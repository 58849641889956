import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCart } from '@/store/actions/cart';
import { RootState } from '@/store/store';
import styled, { css } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import {
  WrapperExternal,
  Logo,
  Drawer,
  Backdrop,
  LinkHandler,
  IconSearch,
  IconUser,
  IconMenu,
} from '@/components/atoms';
import {
  HeaderIcons,
  HeaderLinks,
  MainMenu,
  MainMenuMobile,
  LanguageSelector,
} from '@/components/molecules';
import { Search } from '@/components/organisms';
import { drawerPreventScroll } from '@/utils/index';
import { IAppBarCmp } from './IAppBarCmp';
import useMedium from '@/hooks/useMedium';
import { useRouter } from 'next/router';
import SearchBar from '../SearchBar/SearchBar';

interface IStyledProps {
  searchMode?: boolean;
  isMobile?: boolean;
  accountVisible?: boolean;
  $scrollTriggered?: boolean;
  isLogged?: boolean;
}

const Container = styled.div`
  background: ${props => props.theme.header.backgroundColor};
  position: relative;
`;

const CustomWrapper = styled(WrapperExternal)<IStyledProps>`
  position: relative;
  min-height: inherit;
` as React.ElementType;

const Navigation = styled.nav<IStyledProps>`
  position: 'relative';
  height: 100%;
  top: 0;
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: block;
  } ;
` as React.ElementType;

const TopContainer = styled.div<ITopContainer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  ${props => props.theme.breakpoints.up('md')} {
    min-height: 48px;
  }
`;

const Column = styled.div``;

const MobileIcons = styled.ul`
  display: flex;
  align-items: center;
  li {
    margin-right: 12px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const HamburgerWrapper = styled.div``;

const CustomMenuIcon = styled(MenuIcon)`
  width: 20px;
  cursor: pointer;
`;

const SearchWrapper = styled.div<IStyledProps>`
  width: 100%;
  padding: 8px 0 12px;
`;

const logoMobile = css``;

const logo = css<IStyledProps>`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  max-width: 500px;
`;

const LogoContainer = styled.div<IStyledProps>`
  ${props => (props.isMobile ? logoMobile : logo)}
  max-width: 150px;
  ${props => props.theme.breakpoints.up('sm')} {
    max-width: 190px;
  }
  a {
    display: block;
  }
` as React.ElementType;

const DrawerContent = styled.div`
  height: 100%;
  width: 85vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MenuBackdrop = styled(Backdrop)`
  touch-action: none;
  z-index: 1000000001;
`;

const Bar = styled.div<IStyledProps>`
  background-color: ${props => {
    return props.$scrollTriggered
      ? props.theme.paletteDefault.offWhite
      : 'transparent';
  }};
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 48px;
  }
  height: 90px;
`;
const BarLogoWrapper = styled.div`
  max-height: 120px;
  height: 100%;
`;
const BarContainer = styled.div`
  background: transparent;
`;

const Wrapper = styled(WrapperExternal)`
  background: ${props => props.theme.header.navigation.backgroundColor};
`;
const NavigationWrapper = styled.div<IStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SearchBarWrapper = styled.div`
  flex-grow: 1;
  max-width: 360px;
  min-width: 120px;
`;

const IconSearchWrapper = styled.div`
  cursor: pointer;
`;

interface ITopContainer {
  paddingBig?: boolean;
}

const CustomAppBar: React.FC<IAppBarCmp> = props => {
  const scrollTriggered = props.scrollTriggered;
  const { navigation, template, links } = props.fields;
  const { locales, locale, currency, menuDrawerHandle } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );
  const dispatch = useDispatch();

  const [searchMode, setSearchMode] = useState(false);
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);
  const [accountViewOnly, setAccountViewOnly] = useState(false);

  const medium = useMedium();
  const router = useRouter();

  useEffect(() => {
    document.body.style.overflow = searchMode ? 'hidden' : 'auto';
  }, [searchMode]);

  const toggleMenuDrawer = useCallback(
    () => (event: any) => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }

      drawerPreventScroll(document, window, !menuDrawerOpen);
      setMenuDrawerOpen(!menuDrawerOpen);
      menuDrawerHandle(!menuDrawerOpen);
    },
    [menuDrawerOpen]
  );

  const toggleSearchMode = useCallback(() => {
    setSearchMode(!searchMode);
  }, [searchMode]);

  const openCart = () => {
    dispatch(toggleCart(true));
  };

  return template === 'prospect_customers' ? (
    <BarContainer>
      <WrapperExternal>
        <Bar $scrollTriggered={scrollTriggered}>
          <BarLogoWrapper>
            <Logo template="yellow" />
          </BarLogoWrapper>
          {links && (
            <HeaderLinks
              links={links}
              template="prospect-landing"
              scrollTriggered={scrollTriggered}
            />
          )}
        </Bar>
      </WrapperExternal>
    </BarContainer>
  ) : (
    <Container>
      <CustomWrapper>
        <TopContainer>
          <Column>
            {!isMobile ? (
              <>
                <LanguageSelector locale={locale} locales={locales} />
              </>
            ) : (
              <>
                <MobileIcons>
                  <li>
                    <HamburgerWrapper
                      onClick={e => {
                        const x = toggleMenuDrawer();
                        setAccountViewOnly(false);
                        x(e);
                      }}>
                      <IconMenu width={18} />
                    </HamburgerWrapper>
                  </li>

                  {!isLogged ? (
                    <></>
                  ) : (
                    <li>
                      <IconSearchWrapper onClick={toggleSearchMode}>
                        <IconSearch width={22} height={22} />
                      </IconSearchWrapper>
                    </li>
                  )}
                </MobileIcons>
              </>
            )}
          </Column>
          <Column>
            <LogoContainer isMobile={isMobile}>
              {template === 'logo_not_clickable' ||
              (medium === 'MirtaPlus' &&
                !router.asPath.includes('account/login')) ? (
                <Logo />
              ) : (
                <LinkHandler href={'/'} passHref>
                  <a>
                    <Logo />
                  </a>
                </LinkHandler>
              )}
            </LogoContainer>
          </Column>
          <Column>
            {!isLogged ? (
              <>
                {isMobile ? (
                  <div
                    onClick={(e: any) => {
                      const x = toggleMenuDrawer();
                      setAccountViewOnly(true);
                      x(e);
                    }}>
                    <IconUser width={'20'} height={'20'} />
                  </div>
                ) : links && links.length > 0 ? (
                  <HeaderLinks
                    links={links}
                    scrollTriggered={scrollTriggered}
                  />
                ) : null}
              </>
            ) : (
              <div>
                <HeaderIcons openCart={openCart} />
              </div>
            )}
          </Column>
        </TopContainer>
        {isMobile && (
          <>
            <MenuBackdrop open={menuDrawerOpen} onClick={toggleMenuDrawer()} />
            <Drawer
              anchor="left"
              open={menuDrawerOpen}
              onClose={toggleMenuDrawer()}
              transitionDuration={400}
              variant="persistent">
              <DrawerContent className="drawerContent">
                {navigation && (
                  <MainMenuMobile
                    {...navigation}
                    locale={locale}
                    clickHandler={toggleMenuDrawer()}
                    currency={currency}
                    isLogged={isLogged}
                    accountViewOnly={accountViewOnly}
                  />
                )}
              </DrawerContent>
            </Drawer>
          </>
        )}
      </CustomWrapper>

      <Wrapper>
        {searchMode ? (
          <SearchWrapper>
            <Search toggleSearchMode={toggleSearchMode} locale={locale} />
          </SearchWrapper>
        ) : (
          !isMobile && (
            <NavigationWrapper isLogged={isLogged}>
              <Navigation>
                {navigation ? (
                  <MainMenu {...navigation} locale={locale} />
                ) : null}
              </Navigation>
              {isLogged && (
                <SearchBarWrapper>
                  <SearchBar onClick={toggleSearchMode}></SearchBar>
                </SearchBarWrapper>
              )}
            </NavigationWrapper>
          )
        )}
      </Wrapper>
    </Container>
  );
};

export default CustomAppBar;
