import { getProductsArtisanById } from '@/lib/api.product';
import { BRANDS_PER_PAGE } from '@/lib/constants';
import { httpGateway } from '@/shared/HttpGateway';
import {
  Brand,
  Facet,
  Pagination,
  SearchResultResponse,
  SearchResultResponse as SearchResultResponseBrand,
} from '@/types/brands_catalog';
import { BrandResultResponse } from '@/types/brands_catalog/models/BrandResultResponse';
import {
  Image,
  SearchResultResponse as SearchResultResponseProduct,
} from '@/types/catalog';
import { removeUndefinedValues, stringifyValues } from '@/utils/objects';
import { getProductsBrandBySlug } from '@/utils/Product';
import { FetchError } from '@/utils/typings/errors';
import {
  mapResultAsync,
  Result,
  sequence,
  swallow,
} from '@/utils/typings/result';
import { createUrlWithParams } from '../utils';

export interface ISearchResultResponseProductNoMeta
  extends Omit<SearchResultResponseProduct, 'metadata'> { }

export type IBrandWithProduct = Brand & {
  product_images: Image[];
  totalProductsNumber: number;
  markup: number | undefined;
  delivery_time: number | undefined;
};

export type BrandsResultResponseWithProducts = {
  data: Array<IBrandWithProduct>;
  pagination: Pagination;
  facets: Array<Facet>;
};

type BrandSearchParams = {
  'items-per-page'?: number;
};

const BRAND_CATALOG_BASE_URL = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API}/brands`;

export function getBrandsCatalog(
  page: number = 1,
  limit: number = BRANDS_PER_PAGE,
  queryParams?: { [key: string]: string | string[] | undefined }
) {
  let query = '';
  if (queryParams) {
    Object.keys(queryParams).forEach(item => {
      // sort_by (with underscore) is not accepted by this api
      if (item === 'sort_by') {
        query += `&sort-by=${queryParams[item]}`;
      }
      query += `&${item}=${queryParams[item]}`;
    });
  }

  const url = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API}/brands?page=${page}&items-per-page=${limit}${query}`;

  return httpGateway.get<SearchResultResponse>(url);
}

export async function getBrandsCatalogWithProducts(
  page: number,
  limit: number,
  queryParams?: { [key: string]: string | string[] | undefined }
): Promise<Result<BrandsResultResponseWithProducts, FetchError>> {
  const brands = await getBrandsCatalog(page, limit, queryParams);

  return mapResultAsync(brands, async brands => {
    let productsResolved: SearchResultResponseProduct[] = [];

    let newBrands = brands as BrandsResultResponseWithProducts;

    if (brands?.data?.length > 0) {
      const data = brands.data.map(async entry => {
        return await getProductsArtisanById(entry.id as string, 3);
      });

      productsResolved = swallow(sequence(await Promise.all(data))) ?? [];
      if (productsResolved && productsResolved.length > 0) {
        productsResolved.map((pr, index) => {
          let dataArrayImages: Image[] = [];
          let totalItems: number | undefined = 0;

          if (pr && pr.data && pr.data.length > 0) {
            pr.data.map(product => {
              if (
                product &&
                product.images_object &&
                product.images_object.length > 0
              ) {
                dataArrayImages.push(product.images_object[0]);
              }
            });

            totalItems = pr?.pagination?.total_items || 0;
          }

          newBrands.data[index].product_images = dataArrayImages;
          newBrands.data[index].totalProductsNumber = totalItems;
          newBrands.data[index].markup = pr?.metadata?.avg_markup;
          newBrands.data[index].delivery_time = pr?.metadata?.avg_delivery_time;
        });
      }
    }

    return newBrands;
  });
}

export function getBrandBySlug(slug: string) {
  const url = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API}/brands/slug/${slug}`;
  return httpGateway.get<BrandResultResponse>(url);
}

export async function getBrandById(id: string) {
  const url = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API
    }/brands/${id}`;
  return httpGateway.get<BrandResultResponse>(url);
}

export async function getBrandsByIds(ids: string[]) {
  const url = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API
    }/brands?brand-id=${ids.join(',')}&items-per-page=250&with-live-products=false`;
  return httpGateway.get<SearchResultResponseBrand>(url);
}

export async function getBrandsByArgIds(...ids: string[]) {
  return await getBrandsByIds(ids);
}

export function searchBrandsByName(name: string) {
  const url = `${process.env.NEXT_PUBLIC_BRANDS_CATALOG_API}/brands?search=${name}&with-live-products=false`;
  return httpGateway.get<SearchResultResponseBrand>(url);
}

export async function getBrandsWithProductsByIds(ids: string[]) {
  const result = await getBrandsByIds(ids);
  let brandsWithProductsPm: IBrandWithProduct[] = [];

  return mapResultAsync(result, async result => {
    if (result && result.data.length > 0) {
      const slugs = result.data.map((brand: Brand) => {
        return brand.name_slug;
      });

      const productsData = await getProductsBrandBySlug(slugs as string[], 3);

      brandsWithProductsPm = result.data.map((brand: Brand, index: number) => {
        return {
          ...brand,
          ...productsData[index],
        };
      });
    }

    let brandsWithProductsPmSorted: IBrandWithProduct[] = [];
    ids.forEach(brand => {
      brandsWithProductsPm.forEach(b => {
        if (b.id === brand) {
          brandsWithProductsPmSorted.push(b);
        }
      });
    });

    return brandsWithProductsPmSorted;
  });
}

export async function searchBrands(
  query: string,
  params: BrandSearchParams = {}
): Promise<SearchResultResponse> {
  const extraParams = stringifyValues(removeUndefinedValues(params));

  const searchEndpoint = createUrlWithParams(BRAND_CATALOG_BASE_URL, {
    search: query,
    'include-facets': 'false',
    ...extraParams,
  });

  try {
    const res = await fetch(searchEndpoint);
    return await res.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
}
