import React from 'react';
import { useTheme } from 'styled-components';

const IconUser: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m16.73,15.24l-.7-.34c-.1-.05-.12-.18-.05-.26l.54-.57c1.73-1.83,2.18-4.44,1.19-6.8-.7-1.66-2.16-2.95-3.91-3.44-1.95-.55-3.97-.18-5.54,1.01-1.57,1.19-2.47,3-2.47,4.96,0,1.59.61,3.11,1.71,4.27l.54.57c.08.08.05.21-.05.26l-.7.34c-2.16,1.05-3.92,2.79-5.02,4.94-.06.11.03.24.15.24l1.76-.02c.06,0,.11-.03.14-.08,1.66-2.67,4.58-4.31,7.7-4.31s6.06,1.66,7.71,4.34c.03.05.08.08.14.08h1.75c.12,0,.2-.13.15-.24-1.09-2.16-2.86-3.9-5.02-4.95Zm-3.27-1.33c-.48.16-.97.24-1.46.24-1.18,0-2.32-.47-3.16-1.34-1.18-1.24-1.52-3.03-.89-4.69.42-1.09,1.28-1.95,2.37-2.37,1.66-.63,3.45-.29,4.68.89,1.23,1.18,1.66,2.95,1.11,4.61-.41,1.25-1.4,2.24-2.65,2.65Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconUser;
