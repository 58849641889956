import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { ISearchResultExtra } from './ISearchResultExtraCmp';
import { Typography } from '@/components/atoms';
import { useTranslation } from 'next-i18next';

const Li = styled.li`
  margin-bottom: 16px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;

const GreyTypography = styled(Typography)`
  font-style: italic;
  color: ${props => props.theme.paletteDefault.primary.main};
`;
const CustomTypography = styled(Typography)`
  font-size: 14px;
`;

const StyledAnchor = styled.a`
  display: block;
  cursor: pointer;
`;

const SearchResultExtra: React.FC<ISearchResultExtra> = ({
  resultType,
  resultItems,
  locale,
  toggleSearchMode,
}) => {
  const { t } = useTranslation('common');
  return !resultItems || resultItems?.length === 0 ? (
    <GreyTypography datatype="body_2"> {t('search.no-results')}</GreyTypography>
  ) : (
    <ul>
      {resultItems.map((item, index) => {
        return (
          <Li key={`${item.slug}-${index}`}>
            <Link href={`/${resultType}/${item.slug}`} locale={locale} passHref>
              <StyledAnchor onClick={toggleSearchMode}>
                <CustomTypography datatype="body_1">
                  {item.name}
                </CustomTypography>
              </StyledAnchor>
            </Link>
          </Li>
        );
      })}
    </ul>
  );
};

export default SearchResultExtra;
