import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Dropdown } from '@/components/molecules';
import { IOption, ILocale } from '@/interfaces/index';

import { ILanguageSelectorCmp } from './ILanguageSelectorCmp';
import { ILocales } from '@/types/index';
interface ILanguageSelector {
  locale: string;
  locales: ILocale[];
}

const DropdownContainer = styled.div`
  min-width: 96px;
`;

const LanguageSelector: React.FC<ILanguageSelectorCmp> = props => {
  const { locale, locales } = props;

  const router = useRouter();
  let localesOptions: IOption[] = [];

  if (locales && locales.length > 1) {
    locales.forEach((loc: ILocales) => {
      localesOptions.push({
        value: loc.code,
        label: loc.name,
        locale: loc.code,
        href: {
          pathname: router.pathname,
          query: { ...router.query },
        },
      });
    });
  }

  return localesOptions.length > 0 ? (
    <DropdownContainer>
      {localesOptions.length > 0 ? (
        <Dropdown selected={locale} options={localesOptions} shallow={false} />
      ) : null}
    </DropdownContainer>
  ) : null;
};

export default LanguageSelector;
