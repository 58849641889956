import React from 'react';
import { useTheme } from 'styled-components';

const IconMenu: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M20.5999 5H3.40011C3.17914 5 3 5.07803 3 5.17429V6.82571C3 6.92197 3.17914 7 3.40011 7H20.5999C20.8209 7 21 6.92197 21 6.82571V5.17429C21 5.07803 20.8209 5 20.5999 5Z"
        fill={color1}
      />
      <path
        d="M20.5999 11H3.40011C3.17914 11 3 11.078 3 11.1743V12.8257C3 12.922 3.17914 13 3.40011 13H20.5999C20.8209 13 21 12.922 21 12.8257V11.1743C21 11.078 20.8209 11 20.5999 11Z"
        fill={color1}
      />
      <path
        d="M20.5999 17H3.40011C3.17914 17 3 17.078 3 17.1743V18.8257C3 18.922 3.17914 19 3.40011 19H20.5999C20.8209 19 21 18.922 21 18.8257V17.1743C21 17.078 20.8209 17 20.5999 17Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconMenu;
