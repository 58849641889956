import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { IconArrowDown } from '@/components/atoms';
import { IAccordion } from './AccordionCmp';

const Accordion: React.FC<IAccordion> = ({ summary, bigArrow, ...props }) => {
  const arrowSize = bigArrow ? 18 : 14;

  return (
    <MuiAccordion {...props}>
      <AccordionSummary
        expandIcon={<IconArrowDown width={arrowSize} height={arrowSize} />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
