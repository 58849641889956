import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LinkItem } from '@/components/atoms';
import { MegaMenu } from '@/components/molecules';
import useConditional from '@/hooks/useConditional';
import { IMainMenuCmp } from './IMainMenuCmp';

const Ul = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: left;
  align-items: center;
  height: 100%;
  & li.main-menu .mega-menu {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms 0ms;
  }
  & li.main-menu.selected .mega-menu {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0ms 0ms;
  }
`;
const Li = styled.li`
  padding: 16px 0 16px;
  padding-right: 32px;
  ${props => props.theme.breakpoints.up('md')} {
    padding-right: 36px;
  }
  ${props => props.theme.breakpoints.up('lg')} {
    padding-right: 48px;
  }

  & a {
    padding: 0;
    position: relative;
    text-decoration: none;
  }
  & a:hover {
    text-decoration: none;
  }
`;

const MainMenu: React.FC<IMainMenuCmp> = props => {
  const { menu } = props.fields;
  const { locale } = props;
  let mainmenu = null;
  const { conditionalContent } = useConditional(menu ?? null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const showMegaMenuHandler = (e: React.MouseEvent) => {
    const target = e.currentTarget as Element;
    const selectedClass = document.querySelector('li.main-menu.selected');
    const timeoutId = setTimeout(
      () => {
        target.classList.add('selected');
        if (target.classList.contains('hasmegamenu')) {
          const overMenu = document.getElementsByClassName('overMenu');
          overMenu[0].classList.add('visible');
        }
      },
      selectedClass ? 0 : 500
    );
    timeoutRef.current = timeoutId;
  };

  const hideMegaMenuHandler = () => {
    clearTimeout(timeoutRef.current as any);
    setTimeout(() => {
      const overMenu = document.getElementsByClassName('overMenu');
      overMenu[0].classList.remove('visible');
      let mainMenuLinks = document.querySelectorAll('li.main-menu');
      for (let i = 0; i < mainMenuLinks.length; i++) {
        mainMenuLinks[i].classList.remove('selected');
      }
    }, 0);
  };

  useEffect(() => {
    document.addEventListener('menuClick', hideMegaMenuHandler);

    return () => {
      document.removeEventListener('menuClick', hideMegaMenuHandler);
    };
  }, []);

  if (menu && menu.length > 0) {
    mainmenu = conditionalContent.map((link, index) => {
      let cls = '';

      if (
        link &&
        link.fields &&
        'linkGrouped' in link.fields &&
        link.fields.linkGrouped &&
        link.fields.linkGrouped.length > 0
      ) {
        cls = 'hasmegamenu';
      }

      if (link && link.fields) {
        return (
          <Li
            key={`${link.sys.id}-${index}`}
            className={'main-menu ' + cls}
            onMouseLeave={hideMegaMenuHandler}
            onMouseEnter={showMegaMenuHandler}>
            <LinkItem
              {...link?.fields?.link}
              locale={locale}
              template="mainmenu"
              showArrow={cls !== 'hasmegamenu'}
            />
            {'linkGrouped' in link.fields &&
            link?.fields?.linkGrouped &&
            link?.fields?.linkGrouped?.length > 0 ? (
              <div className={'mega-menu'}>
                <MegaMenu
                  locale={locale}
                  menu={link?.fields?.linkGrouped}
                  parentLink={link?.fields?.link}
                />
              </div>
            ) : null}
          </Li>
        );
      }
    });
  }

  return <Ul>{mainmenu}</Ul>;
};

export default MainMenu;
