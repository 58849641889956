import React from 'react';
import styled from 'styled-components';

const Total = styled.span`
  position: absolute;
  left: 10px;
  top: -3px;
  font-size: 10px;
  text-align: center;
  padding: 0 2px;
  border-radius: 50%;
  background: ${props => props.theme.paletteDefault.primary.main};
  border: 1px solid ${props => props.theme.paletteDefault.offWhite};
  box-shadow: none;
  line-height: 14px;
  min-width: 16px;
  color: ${props => props.theme.paletteDefault.white};
  font-weight: 200;
  letter-spacing: 0;
`;

interface ITotalCircle {
  children: React.ReactNode;
}

const TotalCircle: React.FC<ITotalCircle> = props => {
  return <Total>{props.children}</Total>;
};

export default TotalCircle;
