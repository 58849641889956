import React from 'react';
import { useTheme } from 'styled-components';

const IconCart: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;

  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m7.55,7.81h-1.52c-.09,0-.17.09-.16.18.05.67.21,1.34.48,1.98.69,1.68,2.15,2.97,3.91,3.45,1.93.53,3.92.16,5.46-1.01,1.42-1.08,2.29-2.68,2.42-4.44,0-.09-.07-.17-.16-.17h-1.52c-.08,0-.15.06-.16.15-.22,2.17-2.06,3.88-4.29,3.88s-4.07-1.7-4.29-3.87c0-.08-.08-.15-.16-.15Z"
        fill={color1}
      />
      <path
        d="m1.43,20.43h21.13c.09,0,.16-.07.16-.16V3.74c0-.09-.07-.16-.16-.16H1.43c-.09,0-.16.07-.16.16v16.53c0,.09.07.16.16.16Zm1.56-14.96c0-.09.07-.16.16-.16h17.68c.09,0,.16.07.16.16v13.07c0,.09-.07.16-.16.16H3.16c-.09,0-.16-.07-.16-.16V5.46Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconCart;
