import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { IconClose, IconUser, IconDivider } from '@/components/atoms';

type StyleProps = {
  isLogged: boolean;
};
const CloseIconWrapper = styled.div<StyleProps>`
  height: 24px;
`;

const IconDividerContainer = styled.div`
  margin: 0 8px;
  height: 24px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  height: 24px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;
const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
`;

interface IMainMenuHeader {
  isLogged: boolean;
  locale: string;
  onClose: (event: any) => void;
  toggleShowCurrencies: () => void;
}

const MainMenuHeader: React.FC<IMainMenuHeader> = props => {
  const { locale, onClose, isLogged } = props;
  const accountHref = !isLogged ? '/account/login' : '/account';
  return (
    <>
      <Container>
        <CloseIconWrapper onClick={onClose} isLogged={isLogged}>
          <IconClose width={24} height={24} />
        </CloseIconWrapper>
        <IconDividerContainer>
          <IconDivider width={24} height={24} />
        </IconDividerContainer>
        <HeaderWrapper>
          <AccountContainer>
            {isLogged ? (
              <div>
                <Link href={accountHref} locale={locale}>
                  <a>
                    <IconUser width={24} height={24} onClick={onClose} />
                  </a>
                </Link>
              </div>
            ) : null}
          </AccountContainer>
        </HeaderWrapper>
      </Container>
    </>
  );
};

export default MainMenuHeader;
