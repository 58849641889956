import { useMediaQuery } from '@material-ui/core';
import Image from 'next/image';
import { useTheme } from 'styled-components';
import { ILogoCmp } from './ILogoCmp';

const Logo: React.FC<ILogoCmp> = ({ template = 'default' }) => {
  const theme = useTheme();

  const smallLogo = useMediaQuery(theme.breakpoints.down('sm'));
  const logo =
    template === 'yellow' ? (
      <Image
        src={theme.logo2.url}
        alt={'Mirta logo'}
        objectFit="cover"
        width={
          smallLogo
            ? theme.logo2.mobileWidth || theme.logo2.width
            : theme.logo2.width
        }
        height={
          smallLogo
            ? theme.logo2.mobileHeight || theme.logo2.height
            : theme.logo2.height
        }
        unoptimized={true}
      />
    ) : (
      <Image
        src={theme.logo.url}
        alt={'Mirta'}
        width={theme.logo.width}
        height={theme.logo.height}
        unoptimized={true}
      />
    );

  return logo;
};

export default Logo;
