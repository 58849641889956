import { AppError } from '@/utils/typings/errors';
import { isErr, isOk, Result } from '@/utils/typings/result';
import useSWR, { Key, Fetcher, SWRResponse } from 'swr';

export function useSWRResult<R, E, Args extends Key>(
  key: Args,
  fetcher: Fetcher<Result<R, E>>
): Omit<SWRResponse<R, E | AppError>, 'mutate'> {
  const swrResponse = useSWR(key, fetcher);

  if (swrResponse.error) {
    return {
      ...swrResponse,
      data: undefined,
      error: new AppError('Error while fetching', swrResponse.error),
    };
  }

  const data =
    swrResponse.data && isOk(swrResponse.data)
      ? swrResponse.data.result
      : undefined;
  const error =
    swrResponse.data && isErr(swrResponse.data)
      ? swrResponse.data.error
      : undefined;

  return {
    ...swrResponse,
    data,
    error,
  };
}
