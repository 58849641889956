import React from 'react';
import { useTheme } from 'styled-components';

const IconSearch: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m8.97,18.68c2.43.76,4.99.36,6.98-1.08l.19-.14,2.93,2.93c.06.06.16.06.22,0l.97-.97c.06-.06.06-.16,0-.22l-2.91-2.91.15-.2c1.55-1.96,2.04-4.51,1.36-6.98-.73-2.62-2.83-4.65-5.48-5.31-2.77-.68-5.59.11-7.53,2.12-1.91,1.97-2.62,4.76-1.91,7.47.66,2.52,2.53,4.51,5.01,5.28Zm-1.82-11.65c1.57-1.57,3.82-2.15,6.01-1.54,1.98.55,3.55,2.12,4.1,4.1.61,2.19.04,4.44-1.54,6.01-1.16,1.16-2.68,1.78-4.28,1.78-.57,0-1.15-.08-1.73-.24-1.98-.55-3.55-2.12-4.1-4.1-.61-2.19-.04-4.44,1.54-6.01Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconSearch;
