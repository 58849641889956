import React from 'react';
import { TotalCircle, IconCart } from '@/components/atoms';
import styled from 'styled-components';

const IconContainer = styled.div`
  position: relative;
`;

interface ICartIcon {
  total: number;
}

const CartIcon: React.FC<ICartIcon> = props => {
  const { total } = props;

  return (
    <IconContainer>
      <IconCart width={22} height={22} />
      {total > 0 && <TotalCircle>{total}</TotalCircle>}
    </IconContainer>
  );
};

export default CartIcon;
