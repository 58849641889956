import React, { useState } from 'react';
import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { IconSearch, Typography, IconClose } from '@/components/atoms';
import Image from 'next/image';
import { ISearchBarCmp } from './ISearchBarCmp';

const IconWrapper = styled.div<StyleProps>`
  padding: 0 8px;

  ${props => props.theme.breakpoints.up('tablet')} {
    padding: 0 5%;
  }
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  display: flex;
`;

const CustomTextField = styled(InputBase)<StyleProps>`
  & input {
    cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  }
`;

const SearchBarContainer = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.paletteDefault.grey1};
  cursor: ${props => (props.clickable === true ? 'pointer' : 'none')};
  border-radius: 20px;
  height: ${props => (props.clickable === true ? '28px;' : '36px;')};
  margin-bottom: 0;
  width: 100%;
  ${props => props.theme.breakpoints.up('tablet')} {
    width: 100%;
  }
`;

type StyleProps = {
  clickable?: boolean;
};
const TextFieldWrapper = styled.div<StyleProps>`
  width: 100%;
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CustomTypography = styled(Typography)`
  color: ${props => props.theme.paletteDefault.grey1};
`;

const SearchBar: React.FC<ISearchBarCmp> = ({
  onClick,
  onClose,
  onChange,
  initialValue,
}) => {
  const { t } = useTranslation('common');
  const [input, setInput] = useState(initialValue || null);
  const iconSize = onClick ? 16 : 20;
  return (
    <SearchBarWrapper>
      <SearchBarContainer clickable={onClick ? true : false} onClick={onClick}>
        <IconWrapper clickable={onClick ? true : false}>
          <IconSearch width={iconSize} height={iconSize} />
        </IconWrapper>
        {onChange ? (
          <TextFieldWrapper>
            <CustomTextField
              inputRef={input => input && input.focus()}
              fullWidth
              onChange={
                onChange
                  ? e => {
                      setInput(e.target.value);
                      onChange(e.target.value);
                    }
                  : undefined
              }
              value={input}
              placeholder={t('search.placeholder')}></CustomTextField>
          </TextFieldWrapper>
        ) : (
          <CustomTypography>{t('common:search.placeholder')}</CustomTypography>
        )}
      </SearchBarContainer>
      {onClose && (
        <IconWrapper clickable={true} onClick={onClose}>
          <IconClose width={24} height={24} />
        </IconWrapper>
      )}
    </SearchBarWrapper>
  );
};

export default SearchBar;
