import { CircularProgress, Typography } from '@/components/atoms';
import { SearchResult, SearchResultExtra } from '@/components/molecules';
import { ISearch } from './ISearchCmp';
import { useSWRResult } from '@/hooks/useSwrResult';
import { searchBrands } from '@/lib/api.brands';
import { searchProducts } from '@/lib/models/product';
import { RootState } from '@/store/store';
import { IArtisanPage, IMagazine } from '@/types/contentful/contentful';
import { debounce } from '@/utils/index';
import { ok, sequence } from '@/utils/typings/result';
import { Backdrop } from '@material-ui/core';
import { ContentfulCollection } from 'contentful';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SearchBar from '@/components/molecules/SearchBar/SearchBar';

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-height: 100vh;
  height: 100%;
  width: 100%;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
`;

const ProgressWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Results = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 32px;
  overflow-x: hidden;

  ${props => props.theme.breakpoints.up('laptop')} {
    /* margin: 0 auto;
    width: 70%; */
    flex-direction: row;
    height: 400px;
  }
`;
const MainResult = styled.div`
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 16px;
  }
  ${props => props.theme.breakpoints.up('laptop')} {
    margin-bottom: 0;
  }
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 24px;

    /* min-width: 900px; */
  }
`;

const AdditionalResults = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 16px;
  }
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 24px;
  }
`;

const AdditionalResultsItems = styled.div`
  height: 50%;
  min-height: 80px;
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.up('lg')} {
    min-width: 320px;
  }
`;

const ResultsTitle = styled.div`
  margin-bottom: 8px;
  padding-bottom: 4px;
`;

const CustomTypography = styled(Typography)`
  font-weight: 600;
`;

const SearchResultWrapper = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${props => props.theme.paletteDefault.primary.main};
  overflow: auto;
  height: calc(100vh - var(--header-height));
  width: 100%;
  ${props => props.theme.breakpoints.up('sm')} {
    height: 60vh;
    max-height: 480px;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1200px;
  }
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  ${props => props.theme.breakpoints.up('tablet')} {
    width: 50%;
  }
`;

interface SearchInfos {
  content: 'magazine';
  url: string;
  inputValue: string;
}
interface Search {
  type: string;
  payload: string | {};
}

const fetcherCms = <T extends IArtisanPage | IMagazine>(
  locale: string,
  payload: SearchInfos
) => {
  const { url, inputValue } = payload;
  return fetch(`${url}?input=${inputValue}&locale=${locale}`)
    .then(r => r.json())
    .then((res: ContentfulCollection<T>) => {
      return res;
    });
};

const fetcherSearch =
  (locale: string) =>
  async ({ query }: { query: string }) => {
    const magazinePayload: SearchInfos = {
      content: 'magazine',
      url: `/api/v1/searchMagazine`,
      inputValue: query,
    };
    const results = await Promise.all([
      searchProducts(query, {
        'items-per-page': 4,
      }),
      searchBrands(query, {
        'items-per-page': 4,
      }).then(ok),

      fetcherCms<IMagazine>(locale, magazinePayload).then(ok),
    ]);

    return sequence(results);
  };

const Search: React.FC<ISearch> = ({ toggleSearchMode, locale }) => {
  const { t } = useTranslation('common');

  const [inputValue, setInputValue] = useState('');

  const segmentPrice = useSelector(
    (state: RootState) => state.userReducer?.customer?.segmentPrice?.value
  );

  const onChangeHandler = useCallback(
    debounce((value: string) => {
      if (value.length === 0 || value.length >= 2) {
        setInputValue(value);
      }
    }, 400),
    []
  );

  const fetcher = useCallback(fetcherSearch(locale), [locale]);

  const { data, isValidating } = useSWRResult(
    inputValue !== '' ? { query: inputValue, segmentPrice } : null,
    fetcher
  );

  const getProducts = () => data?.[0] ?? null;

  const getBrands = () => {
    const brands = data?.[1]?.data;
    if (brands) {
      return brands.map(brand => {
        return {
          name: brand.name,
          slug: brand.name_slug,
        };
      });
    }

    return null;
  };

  const getMagazine = () => {
    const cmsData = data?.[2];
    if (cmsData) {
      return cmsData.items?.map(item => {
        return {
          name: item.fields.title,
          slug: item.fields.slug,
        };
      });
    }

    return null;
  };

  const artisanList = (
    <SearchResultExtra
      resultType="artisans"
      resultItems={getBrands()}
      locale={locale}
      toggleSearchMode={toggleSearchMode}
    />
  );

  const articlesList = (
    <SearchResultExtra
      resultType="magazine"
      resultItems={getMagazine()}
      locale={locale}
      toggleSearchMode={toggleSearchMode}
    />
  );

  return (
    <Container>
      <SearchWrapper>
        <SearchBarWrapper>
          <SearchBar
            initialValue={inputValue}
            onClose={toggleSearchMode}
            onChange={onChangeHandler}></SearchBar>
        </SearchBarWrapper>

        {inputValue && (
          <SearchResultWrapper>
            {!isValidating ? (
              <Results>
                <MainResult>
                  <SearchResult
                    toggleSearchMode={toggleSearchMode}
                    products={getProducts()}
                    inputvalue={inputValue}
                    locale={locale}></SearchResult>
                </MainResult>

                <AdditionalResults>
                  <AdditionalResultsItems>
                    <ResultsTitle>
                      <CustomTypography datatype="body_1">
                        {t('artisanB2b_plural')}
                      </CustomTypography>
                    </ResultsTitle>
                    {artisanList}
                  </AdditionalResultsItems>
                  <AdditionalResultsItems>
                    <ResultsTitle>
                      <CustomTypography datatype="body_1">
                        {t('magazine')}
                      </CustomTypography>
                    </ResultsTitle>
                    {articlesList}
                  </AdditionalResultsItems>
                </AdditionalResults>
              </Results>
            ) : (
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            )}
          </SearchResultWrapper>
        )}
      </SearchWrapper>
      <Backdrop open={true} onClick={toggleSearchMode}></Backdrop>
    </Container>
  );
};

export default Search;
